export enum ADMIN_MAP_CONSTS {
  PROGRAMS = 0,
  COMPANY_MAP_EDITOR = 1,
  ORIGIN_MAP_EDITOR = 2,
  ROUTE_MAP_EDITOR = 3,
  ELEMENTS = 4,
  SCHEME_TYPES = 4,
  SCHEMES = 5,
  INDUCTION_LOCATIONS = 6,
  USERS = 7,
  EXTRAS = 8,
}
